<template>
  <div>
    <b-card
      title="Twoje indywidualne materiały"
    >
      <div v-html="individualMessage" />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/shop/useEcommerce'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      individualMessage: '',
    }
  },
  created() {
    this.fetchMe()
  },
  setup() {
    const { handleCartActionClick } = useEcommerceUi()

    return { handleCartActionClick }
  },
  methods: {
    fetchMe() {
      this.$http.get(`${this.$store.state.apiDomain}/api/me`).then(response => {
        this.individualMessage = response.data.user.individual_message
      })
    },
  },
}
</script>

<style lang="scss">
 img{
   max-width: 100% !important;
 }
</style>